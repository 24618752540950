* {
  margin: 0;
  padding: 0;

  /* --- Site colours and fonts --- */
  --grey1: #f9f9fb;
  --grey2: #eeedf2;
  --grey3: #999999;
  --grey4: #777777;
  --grey5: #333333;
  --black: #000000;
  --white: #ffffff;
  --green1: #d3f1f2;
  --green2: #92d4d0;
  --green3: #449690;
  --green4: #00403b;

  --font1: 'Urbanist';
  --font2: 'Outfit';

  font-family: var(--font1);

  /* --- Toastify-changes --- */
  --toastify-color-success: var(--green3) !important;
  --toastify-icon-color-success: var(--green3) !important;
  --toastify-font-family: var(--font1) !important;
}

/* --- Header --- */
header {
  background-color: var(--green4);
}
.menu-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px;
}
.menu-area > a {
  color: var(--green2);
  text-decoration: none;
  display: flex;
}
.logo > div > h3{
  font-weight: 400;
  font-size: medium;
  padding: 0 5px;
}
/* nav {
  min-width: 1200px;
  max-width: 1600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
.menu-item {
  color: var(--green2);
  display: flex;
}
.menu-item > h3 {
  font-weight: 400;
}
.menu-item > h3,
.menu-item > img {
  padding: 0 5px;
}

/* --- Launcher --- */
.launcher-page {
  padding: 20px;
}
h1 {
  color: var(--green4);
}
.filter-box {
  background-color: var(--grey1);
  border-radius: 10px;
  border: 1px solid var(--grey2);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 0px;
  padding: 10px;
  margin: 20px 0;
}
select {
  padding: 3px;
  border: 1px solid var(--grey2);
  border-radius: 15px;
  font-size: 16px;
}
.party > div,
.party2 > div {
  background-color: var(--white);
  border: 1px solid var(--grey2);
  border-radius: 15px;
  padding: 3px;
}
.party2 > legend {
  color: var(--grey1)
}
.party2 > div {
  color: var(--grey4)
}
.manifest-box {
  border: 1px solid var(--grey2);
  border-radius: 15px;
}
.header-box {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: var(--grey1);
  color: var(--grey4);
  padding: 3px;
}
.manifests {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  cursor: pointer;
  padding: 3px;
}
.manifests:nth-child(odd) {
  background-color: var(--grey1);
}
.manifests:hover {
  background-color: var(--green3);
  color: var(--white);
}

/* --- ManifestPage --- */
.manifest-content {
  padding: 30px 20px;
}
.back-btn {
  text-decoration: none;
  color: var(--white);
  background-color: var(--green3);
  border-radius: 20px;
  padding: 10px 20px;
  margin: 10px;
}

/* --- Show info --- */
.info-box {
  padding-top: 30px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.manifest-info { 
  grid-area: 1 / 1 / 3 / 6; 
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 10px;
  background-color: var(--grey1);
  border: 1px solid var(--grey2);
  border-radius: 15px;
  color: var(--grey4);
  padding: 5px;
}
.info > div {
  background-color: var(--white);
  border: 1px solid var(--grey2);
  border-radius: 15px;
  color: var(--grey4);
  padding: 3px;
}
.filters { 
  grid-area: 1 / 6 / 3 / 10;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px; 
  background-color: var(--grey1);
  border: 1px solid var(--grey2);
  border-radius: 15px;
  padding: 5px;
}
.filters > div > legend {
  color: var(--grey4);
} 
.filters > div > input {
  border: 1px solid var(--grey2);
  border-radius: 15px;
  padding: 3px;
  font-size: 16px;
}
.edi-log { 
  grid-area: 1 / 10 / 4 / 13; 
  border: 1px solid var(--grey2);
  border-radius: 15px;
}
.edi-name {
  background-color: var(--grey1);
  text-align: center;
  color: var(--grey4);
}
.edi-content {
  overflow: auto;
  font-size: small;
  padding: 3px;
}
.toggles { 
  grid-area: 3 / 6 / 4 / 10; 
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.toggle > .toggle-name {
  padding: 0 5px;
}
.vsa-info { 
  grid-area: 3 / 1 / 4 / 6; 
  background-color: var(--grey1);
  border: 1px solid var(--grey2);
  border-radius: 15px;
  color: var(--grey4);
  padding: 3px;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--green3);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--green3);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* --- Show Docs --- */
.docs-box { 
  /* Tilføj padding her */
  font-size: small;
  margin-top: 20px;
  border: 1px solid var(--grey2);
  border-radius: 15px;
}
.docs-header-box {
  display: grid;
  grid-template-columns: repeat(25, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  /* display: flex; */
  background-color: var(--grey1);
  color: var(--grey4);
  padding: 3px;
}
/* .docs-header-box > div {
  min-width: 76px;
  max-width: 76px;
} */
.docs-content {
  display: grid;
  grid-template-columns: repeat(25, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  /* display: flex; */
  cursor: pointer;
  padding: 3px;
}
/* .docs-content > div {
  min-width: 76px;
  max-width: 76px;
  position: relative;
} */
.docs-content:nth-child(odd) {
  background-color: var(--grey1);
}
.docs-content:hover {
  background-color: var(--green3);
  color: var(--white);
}
.char-limit {
  overflow: hidden;
  max-width: 10ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}
/* .char-limit:hover {
  text-overflow: inherit;
} */
/* .hover {
  position: absolute;
  width: 150px;
  bottom: 30px;
  z-index: 99;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--white);
  color: var(--black);
} */
/* --- Popup --- */
.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: context-menu;
}
.popup-content {
  background-color: var(--white);
  position: relative;
  padding: 10px;
  border-radius: 15px;
  font-size: medium;
}
.popup-options {
  list-style: none;
}
.option {
  border-radius: 15px;
  padding: 3px;
  color: var(--grey4)
}
.option:hover {
  background-color: var(--grey1);
}
.clickable {
  cursor: pointer;
  color: var(--black);
}
.clickable:hover {
  background-color: var(--green1);
}
.popup-weight,
.popup-consign {
  padding: 20px;
  /* width: 350px; */
}
.popup-weight > h2,
.popup-pod > h2,
.popup-consign > h2 {
  text-align: center;
}
.popup-weight > div,
.popup-pod > div,
.popup-consign > div {
  padding: 5px;
}
.popup-weight > div > legend,
.popup-pod > div > legend,
.popup-consign > div > legend {
  color: var(--grey4)
}
.weight-folder, 
.weight-container,
.weight-bl,
.original-pod > div,
.consign-folder > div,
.consign-manifest > div {
  background-color: var(--grey1);
  border: 1px solid var(--grey2);
  border-radius: 15px;
  color: var(--grey4);
  padding: 3px;
}
.weight-colli,
.weight-weight {
  border: 1px solid var(--grey2);
  border-radius: 15px;
  color: var(--grey4);
  padding: 3px;
}
.weight-inputs,
.weight-btns,
.pod-btns {
  display: flex;
  justify-content: space-between;
}
.weight-colli,
.pod-btns > .cancel {
  margin-right: 15px;
}
.weight-weight,
.pod-btns > .ok {
  margin-left: 15px;
}
.w-colli > input,
.w-weight > input {
  width: 100px;
}
.btn {
  border-radius: 15px;
  color: var(--white);
  text-align: center;
  padding: 5px;
  width: 100px;
  margin-top: 10px;
  cursor: pointer;
}
.ok {
  background-color: var(--green4);
}
.cancel {
  background-color: var(--grey4);
}
.new-pod > input {
  border: 1px solid var(--grey2);
  border-radius: 15px;
  width: 96%;
  padding: 4px;
}
.popup-consign > .consign-ee,
.popup-consign > .consign-or {
  padding: 0;
}
.consign-ee,
.consign-or {
  border: 1px solid var(--grey2);
  border-radius: 15px;
  margin: 5px; 
}
.consign-ee > legend,
.consign-or > legend {
  background-color: var(--grey1);
  padding: 3px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.consign-ee > div,
.consign-or > div {
  padding: 3px;
}

.consign-btn {
  margin: 10px auto 0;
}
